import React, { useContext, useState, useRef } from "react";
import { Card, CardBody, Col, Collapse, Row, Spinner } from "reactstrap";
import ImageComponent from "../common/ImageComponent";
import { _imagesURL, adminBaseURL } from "../../constants/API";
import Like from "../../assets/images/icon/like.png";
import Liked from "../../assets/images/icon/liked.png";
import Reshare from "../../assets/images/icons/repost.svg";
import ReshareActive from "../../assets/images/icons/repost-active.svg";
import Save from "../../assets/images/icons/save.svg";
import Saved from "../../assets/images/icons/saved.svg";
import Comment from "../../assets/images/icon/comment.png";
import Avatar from "../../assets/images/icon/placeholder_Awatar.png";
import { Language } from "../../App";
import { RiEditLine } from "react-icons/ri";
import { authorize } from "../../shared/authorize";
import { toast } from "react-toastify";
import axios from "axios";
import { UnixToRelative } from "../notification/Notification";
import RepostUserModal from "./RepostUserModal";
import { useCallback } from "react";
import { useEffect } from "react";

const PostFooter = ({
  likes_count,
  setPostId,
  original_id,
  handleLikeUsers,
  like_users_images,
  handleLike,
  isLike,
  handleGetComments,
  setPostModal,
  comments_count,
  handleRepost,
  isShare,
  isSave,
  handleSave,
  setCommentsModal, //for profile.jsx
  profile_current_url,
  type,
  pollData,
  pollDays,
  shares_count,
  value
}) => {
  const profile_save_url = sessionStorage.getItem("profile_url");
  const { lang } = useContext(Language);
  const userData = localStorage.getItem("SignupData");
  const user = userData ? JSON.parse(userData) : null;
  const user_id = user?.data?.id;
  const [isOpen, setIsOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const collapseRef = useRef(null); 

  const toggleModal = useCallback((id) => {
    // setPostId(id);
    setModalOpen((prev) => !prev);
  }, []);
  const repostButtonRef = useRef(null); // Reference for the repost button

  // Toggle collapse visibility
  const toggleCollapse = () => setIsOpen(!isOpen);

  // Function to get the position of the repost button
  const getRepostButtonPosition = () => {
    if (repostButtonRef.current) {
      const rect = repostButtonRef.current.getBoundingClientRect();
      return { top: rect.bottom + window.scrollY, left: rect.left };
    }
    return { top: 0, left: 0 };
  };
  const handleRepostClick = () => {
    if (type !== "poll") {
      // When type is not "poll"
      if (!isOwner) {
        // Handle when type is not "poll" and isOwner is false
        console.log("Type is other and isOwner is false");
        // Add your logic for when the user is not the owner here (e.g., restrictions)
        handleRepost(original_id);
      } else {
        // Handle when type is not "poll" and isOwner is true
        console.log("Type is other and isOwner is true");
        setModalOpen(true);
      }
    } else {
      // When type is "poll"
      if (isOwner) {
        // Handle when type is "poll" and isOwner is true
        console.log("Type is poll and isOwner is true");
        // You can add specific logic here for the case when the owner is interacting with the poll
        setModalOpen(true); // or any other logic you'd like to add for this case
      } else {
        // Handle when type is "poll" and isOwner is false
        if (!isShare) {
          toggleCollapse(); // Toggle collapse when isShare is false
        } else {
          handleRepost(original_id); // Repost when isShare is true
        }
      }
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (collapseRef.current && !collapseRef.current.contains(event.target)) {
        setIsOpen(false); // Close the collapse if clicked outside
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const isOwner = value?.user?.id === user_id
  const repostTitle = isOwner
    ? `${shares_count} ${lang?.repost}`
    : `${isShare ? lang?.reposted : lang?.repost}`;

  return (
    <>
      <div>
        {likes_count > 0 && (
          <>
            <Row className="ps-4 pe-3 py-2">
              <Col
                className="d-flex cursor"
                onClick={() => {
                  // if (profile_current_url !== profile_save_url) {
                    setPostId(original_id);
                    handleLikeUsers(original_id);
                  // }
                }}
              >
                {/* {console.log(like_users_images)} */}
                {/* {like_users_images &&
                  like_users_images.map((data) => (
                    <div
                      className="bg-white rounded-circle"
                      style={{
                        marginLeft: "-10px",
                        padding: "1px",
                        border: "1px solid rgba(29, 193, 221, 1)",
                      }}
                    >
                      <ImageComponent
                        src={data && data !== ".." ? _imagesURL + data : Avatar}
                        img_width={25}
                        img_height={25}
                        compClassName="rounded-circle"
                      />
                    </div>
                  ))} */}

                {like_users_images && like_users_images.length > 0 ? (
                  like_users_images.map((data, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-circle"
                      style={{
                        marginLeft: "-10px",
                        padding: "1px",
                        border: "1px solid rgba(29, 193, 221, 1)",
                      }}
                    >
                      <ImageComponent
                        src={data && data !== ".." ? _imagesURL + data : Avatar}
                        img_width={25}
                        img_height={25}
                        compClassName="rounded-circle"
                      />
                    </div>
                  ))
                ) : (
                  <div
                    className="bg-white rounded-circle"
                    style={{
                      marginLeft: "-10px",
                      padding: "1px",
                      border: "1px solid rgba(29, 193, 221, 1)",
                    }}
                  >
                    <ImageComponent
                      src={Avatar}
                      img_width={25}
                      img_height={25}
                      compClassName="rounded-circle"
                    />
                  </div>
                )}


                <p className="ps-2 fs-14 fs-md-12 roboto-medium mb-0 align-self-center text-ellips-162px hover-blue">
                  {likes_count > 3
                    ? `& ${likes_count - 3} ${lang?.other_have_liked}`
                    : `${lang?.have_liked}`}
                </p>
              </Col>
            </Row>
            <hr className="m-0" />
          </>
        )}
        <div className="d-flex px-3 py-2 mt-2 justify-content-between align-items-center">
          <div className="d-flex gap-lg-4 gap-md-3 gap-sm-2">
            <div
              className="d-flex gap-1 cursor"
              onClick={() => handleLike(original_id)}
            >
              <div>
                <img width={18} height={18} src={isLike ? Liked : Like} alt="..." />
              </div>
              <div className="align-self-end hover-blue">
                <p className="mb-0 fw-bold fs-14 fs-md-12">
                  {likes_count > 0 && likes_count}{" "}
                  {likes_count > 1 ? lang?.likes : lang?.like}
                </p>
              </div>
            </div>
            <div
              className="d-flex gap-1 cursor justify-content-center"
              onClick={() => {
                setPostId(original_id);
                handleGetComments(original_id);
                if (profile_current_url === profile_save_url) {
                  setCommentsModal(true);
                } else {
                  setPostModal(true);
                }
              }}
            >
              <div>
                <img width={18} height={18} src={Comment} alt="..." />
              </div>
              <div className="align-self-end hover-blue">
                <p className="mb-0 fw-bold fs-14 fs-md-12">
                  {comments_count > 0 ? comments_count : ""} {lang?.comments}
                </p>
              </div>
            </div>
            <div
              ref={repostButtonRef} // Attach ref to the repost button
              className="d-flex gap-1 cursor"
              onClick={handleRepostClick}
            >
              <div>
                <img
                  src={isShare ? ReshareActive : Reshare}
                  width={18}
                  height={18}
                  alt="..."
                />
              </div>
              <div className="align-self-end hover-blue">
                <p
                  className={`mb-0 fw-bold fs-14 fs-md-12 ${isShare && "text-blue"
                    }`}
                >
                  {repostTitle}
                  {/* {shares_count} {isShare ? lang?.reposted : lang?.repost} */}
                </p>
              </div>
            </div>
            <div
              className="d-flex gap-1 cursor"
              onClick={() => handleSave(original_id)}
            >
              <div>
                <img
                  className="w-lg-23px"
                  title={
                    isSave ? lang?.save_description : lang?.unsave_description
                  }
                  src={isSave ? Saved : Save}
                  width={18}
                  height={18}
                  alt="..."
                />
              </div>
              <div className="align-self-end hover-blue">
                <p
                  className={`mb-0 fw-bold fs-14 fs-md-12 ${isSave && "text-blue"
                    }`}
                >
                  {isSave ? lang?.saved : lang?.save}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Collapse box (popup style) */}
      <div ref={collapseRef}>
      <Collapse isOpen={isOpen}   >
      <Card
        style={{
          position: 'absolute',
          top: `${getRepostButtonPosition().top + 5}px`,
          left: `${getRepostButtonPosition().left}px`,
          zIndex: 1000,
          width: '22%',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
      >
          <RepostCard
            repost={handleRepost}
            original_id={original_id}
            toggleCollapse={toggleCollapse}
            pollData={pollData}
            pollDays={pollDays}
          />

        </Card>
      </Collapse>
      </div>
      {modalOpen && <RepostUserModal toggleModal={toggleModal} modal={modalOpen} postId={original_id} />}

    </>
  );
};

export default PostFooter;
const RepostCard = ({ repost, original_id, toggleCollapse, pollData, pollDays }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => setPopupOpen(true);
  const handleClosePopup = () => setPopupOpen(false);
  const handlePost = () => {
    setPopupOpen(false);
  };
  return (
    <>
      <CardBody
        style={{
          display: "flex",
          flexDirection: "column", // Stack items vertically
          gap: "3px", // Add space between elements
          width: "100%", // Allow card body to take full width
          cursor: "pointer", // Change cursor to pointer
        }}
      >
        {/* First section: Icon + Text */}
        <div className="hover-effect" onClick={handleOpenPopup}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <RiEditLine size={16} />
            <p className="fw-bold" style={{ margin: 0, flex: 1 }} >Repost with your thoughts.</p>
          </div>
          <small className="text-muted" style={{ marginLeft: "26px" }}>
            Repost this poll with your thoughts.
          </small>
        </div>

        {/* Second section: Icon + Text */}
        <div className="hover-effect" onClick={() => {
          repost(original_id);
          toggleCollapse()
        }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img src={Reshare} alt="Repost Icon" width={16} height={16} />
            <p className="fw-bold" style={{ margin: 0, flex: 1 }} >Repost</p>
          </div>
          <small className="text-muted" style={{ marginLeft: "26px" }}>
            Repost this poll without your thoughts.
          </small>
        </div>
      </CardBody>
      <RepostPollPopup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        onPost={handlePost}
        pollData={pollData}
        pollDays={pollDays}
      />
    </>
  );
};


const RepostPollPopup = ({ isOpen, onClose, onPost, pollData, pollDays }) => {
  const { lang } = useContext(Language);
  const [isLoading, setIsLoading] = useState(false);
  const [caption, setCaption] = useState(''); // State to store the caption
  if (!isOpen) return null;
  const repostWithThoughts = async () => {
    if (caption === "") {
      toast.error("Caption is required!")
      return;
    }
    try {
      setIsLoading(true)
      const response = await axios.post(
        `${adminBaseURL}user/post/repost/${pollData?.original_id}`, // The endpoint with postId
        { caption }, // The data payload to send (caption)
        authorize()
      );
      // Handle the response, for example showing a success message
      if (response.data.status) {
        setIsLoading(false)
        setCaption(''); // Clear the caption after reposting
        // toast.success('Reposted successfully!');
        onClose()
        return response.data; // Return the response data (you can handle this as needed)
      } else {
        toast.error(response.data.message || 'Something went wrong.');
        setIsLoading(false)
      }
    } catch (error) {
      toast.error(error.message || 'An error occurred while reposting.');
      console.error('Error reposting:', error); // Optional: Logging the error
      setIsLoading(false)
    }
  };
  return (
    <div
      className="modal d-block"
      tabIndex="-1"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h5 className="modal-title">Repost Poll</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <textarea

              className="form-control mb-3"
              rows="3"
              placeholder="Write your thoughts about this poll..."
              style={{ resize: "none", backgroundColor: "#f7f7f7" }}
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            ></textarea>
            <div className="p-3 border rounded">
              <div className="d-flex align-items-center mb-3">
                <img
                  src={pollData?.user?.image ? _imagesURL + pollData?.user?.image : Avatar}
                  alt="User"
                  className="rounded-circle me-2"
                  width={"30px"}
                  height={"30px"}
                />
                <div>
                  <strong>{pollData?.user?.name}</strong>
                  <p className="text-muted mb-0" style={{ fontSize: "0.9rem" }}>
                    {lang.posted} <UnixToRelative unixTimestamp={pollData?.time} />
                  </p>
                </div>
              </div>
              <p>{pollData?.title}</p>
              <div className="pt-3 ">
              {pollData?.media[0] && ( <div className="mt-3 d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                  
                    <div className="image-preview mb-3">
                      <img
                        src={pollData?.media[0]
                          ? _imagesURL + pollData?.media[0]
                          : Avatar}
                        alt="Poll Preview"
                        className="img-fluid rounded"
                        style={{ maxWidth: '100%', height: '300px' }}
                      />
                    </div>
                 
                </div>
 )}

                <div className=" mt-3" >

                  {[pollData?.option_1,
                  pollData?.option_2,
                  pollData?.option_3,
                  pollData?.option_4].filter(data => data !== "" && data !== null) // Filter out empty or null values
                    .map((option, index) => (
                      <div className="custom-box mb-2 cursor" key={index}>
                        {option}
                      </div>
                    ))}

                </div>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <span className="text-muted">{pollData.votes_count} Votes</span>
                <span className="text-muted">{pollDays(pollData.time, pollData.duration)}</span>
              </div>
            </div>
          </div>
          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn px-3"
              onClick={onClose}
              style={
                { backgroundColor: "white", border: "1px solid #848798", color: "#848798" }
              }
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn px-4"
              onClick={repostWithThoughts}
              disabled={isLoading}
              style={
                { backgroundColor: "#1DC1DD", color: "white" }
              }
            >
              {isLoading ? <Spinner /> : "Post"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


